import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";
import { RootState } from "../store";


const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

interface LoginCredentials {
    email: string;
    password: string;
  }

export const userLogin = createAsyncThunk(
    "login",
    async (credentials: LoginCredentials, { rejectWithValue }) => {
      try {
        const res = await axios.post(`${apiUrl}auth/login`, {
          email: credentials.email,
          password: credentials.password,
        });
        if (res.status === 200) {
          toast.success("Login successful!", {
            style: {
              border: '1px solid #2B8C34',
              backgroundColor: '#2B8C34',
              color: '#FFFFFF',
              fontSize: 14,
              fontFamily: 'Sans-Regular',
            },
            position: "bottom-right",
          });
          let data = res.data;
          return data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Invalid Credentials!",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        } else {
          toast.error("Invalid Credentials!",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        }
      }
    }
  );

  interface ForgotCredentials {
    email: string;
  }
  
export const userForgotPassword = createAsyncThunk(
    "userForgotPassword",
   async (credentials: ForgotCredentials, { rejectWithValue }) => {
      try {
        const res = await axios.post(`${apiUrl}auth/forgot-password`, {
          email: credentials.email,
        });
        if (res.status === 200) {
          toast.success("Please check your email for the reset instructions sent", {
            style: {
              border: '1px solid #2B8C34',
              backgroundColor: '#2B8C34',
              color: '#FFFFFF',
              fontSize: 14,
              fontFamily: 'Sans-Regular',
            },
          });  
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Please check that the credentials entered is valid!",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        } else {
          toast.error("Please check that the credentials entered is valid!",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        }
      }
    }
  );


  interface validateForgotOTPCredentials {
    otp: string;
  }
  export const validateForgotOTP = createAsyncThunk(
    "validateForgotOTP",
   async (credentials: validateForgotOTPCredentials, { rejectWithValue, getState }) => {
    const { auth } = getState() as RootState;
      try {
        const res = await axios.post(`${apiUrl}auth/forgot-password-verify`, {
          temp_token: auth.encrypted_data,
          otp: parseFloat(credentials.otp)
        });
        if (res.status === 200) {
          toast.success("Verification successful, Please proceed to set a new password", {
            style: {
              border: '1px solid #2B8C34',
              backgroundColor: '#2B8C34',
              color: '#FFFFFF',
              fontSize: 14,
              fontFamily: 'Sans-Regular',
            },
          });  
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Please check that the credentials entered is valid!",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        } else {
          toast.error("Please check that the credentials entered is valid!",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        }
      }
    }
  );

  interface ResetCredentials {
    otp: string;
    password: string;
  }
  
  export const userResetPassword = createAsyncThunk(
    "userResetPassword",
    async (credentials: ResetCredentials, { rejectWithValue, getState }) => {
      const { auth } = getState() as RootState;
      try {
        const res = await axios.post(`${apiUrl}auth/forgot-password-complete`, {
          temp_token: auth.encrypted_data,
          otp : credentials.otp,
          password: credentials.password,
        });
        if (res.status === 200) {
          toast.success(
            `Your password has been changed successfully, Kindly login again!`,{
              style: {
                border: '1px solid #2B8C34',
                backgroundColor: '#2B8C34',
                color: '#FFFFFF',
                fontSize: 14,
                fontFamily: 'Sans-Regular',
              },
            }
          );
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error(
            "Oops, an error occurred, please request again.",{
              style:{
                fontSize: 14,
                fontFamily: 'Sans-Regular'
              }
            }
          );
          return rejectWithValue(error);
        } else {
          toast.error(
            "Oops, an error occurred, please request again.",{
              style:{
                fontSize: 14,
                fontFamily: 'Sans-Regular'
              }
            }
          );
          return rejectWithValue(error);
        }
      }
    }
  );

  interface onboardMerchantCredentials {
    full_name: string;
    email: string;
    phone_number: string;
    password: string;
  }

export const onboardMerchant = createAsyncThunk(
    "onboardMerchant",
    async (credentials: onboardMerchantCredentials, { rejectWithValue }) => {
      try {
        const res = await axios.post(`${apiUrl}auth/register`, {
          email: credentials.email,
          password: credentials.password,
          full_name: credentials.full_name,
          phone_number: credentials.phone_number
        });
        if (res.status === 200) {
          toast.success("Please proceed to verify your business account", {
            style: {
              border: '1px solid #2B8C34',
              backgroundColor: '#2B8C34',
              color: '#FFFFFF',
              fontSize: 14,
              fontFamily: 'Sans-Regular',
            },
            position: "bottom-right",
          });
          let data = res.data;
          return data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("User with this email already exists",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        } else {
          toast.error("User with this email already exists",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        }
      }
    }
  );


  export const verifyMerchantEmail = createAsyncThunk(
    "verifyMerchantEmail",
   async (credentials: validateForgotOTPCredentials, { rejectWithValue, getState }) => {
    const { auth } = getState() as RootState;
      try {
        const res = await axios.post(`${apiUrl}auth/verify-account`, {
          temp_token: auth.encrypted_data,
          otp: credentials.otp
        });
        if (res.status === 200) {
          toast.success("Verification successful, Please proceed to finish your registration ", {
            style: {
              border: '1px solid #2B8C34',
              backgroundColor: '#2B8C34',
              color: '#FFFFFF',
              fontSize: 14,
              fontFamily: 'Sans-Regular',
            },
          });  
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Please check that the credentials entered is valid!",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        } else {
          toast.error("Please check that the credentials entered is valid!",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        }
      }
    }
  );


  interface logoType {
    file: File
  }

  export const uploadBusinessLogo = createAsyncThunk(
    'uploadBusinessLogo',
    async ({ file } : logoType, { rejectWithValue, dispatch }) => {
      let formData = new FormData()
      formData.append("image", file);
      try {
        const res = await axios({      
          method: "post",
          url: `${apiUrl}auth/upload-image`,
          data: formData,
          headers: { 
            "Content-Type": "multipart/form-data",
          },
        
      })
        if(res.status === 200){
            toast.success("Logo uploaded successfully!", {
              style: {
                border: '1px solid #2B8C34',
                backgroundColor: '#2B8C34',
                color: '#FFFFFF',
                fontSize: 14,
                fontFamily: 'Sans-Regular',
              },
              position: "top-right",
            });
            return res.data
        }
      } catch (error:any) {
        // return custom error message from API if any
        if (error.response && error.response.status === 400) {
          toast.error("Error while uploading logo",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        } else {
          toast.error("Error while uploading logo",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        }
      }
    }
  )


  interface completeOnboardProcessCreds {
    business_name: string;
    business_industry: string;
  }

  export const completeOnboardProcess = createAsyncThunk(
    "completeOnboardProcess",
    async (credentials: completeOnboardProcessCreds, { rejectWithValue, getState }) => {
      const {auth} = getState() as RootState;
      try {
        const res = await axios.post(`${apiUrl}auth/complete/business-info`, {
          business_logo: auth.logoUrl,
          business_name: credentials.business_name,
          business_industry: credentials.business_industry
        },
        {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getToken()}`
          }, 
        }
        );
        if (res.status === 200) {
          toast.success("Sign up process completed, Welcome to Rewrd!", {
            style: {
              border: '1px solid #2B8C34',
              backgroundColor: '#2B8C34',
              color: '#FFFFFF',
              fontSize: 14,
              fontFamily: 'Sans-Regular',
            },
            position: "top-right",
          });
          let data = res.data;
          return data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("User with this email already exists",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        } else {
          toast.error("User with this email already exists",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        }
      }
    }
  );