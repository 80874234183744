import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchDashboardMetrics } from "./dashboardActions";
import { metricsType } from "../../types/dashboard";




type DashboardState = {
    metrics: metricsType;
 };


const initialState: DashboardState = {
    metrics: {
        customer_earned_points: 0,
        customer_earned_points_change: "",
        customer_earned_points_percentage_change: 0,
        customer_redemed_points: 0,
        customer_redemed_points_change: "",
        customer_redemed_points_percentage_change: 0
    }
};


  export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
      logOut: () => {
        localStorage.setItem("token", "");
        localStorage.clear();
  
        return initialState;
      },
    },
    extraReducers: (builder) => {   
      // fetch dashbaord metrics
      builder.addCase(fetchDashboardMetrics.pending, (state) => {
   ;
      });
      builder.addCase(
        fetchDashboardMetrics.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.metrics = action.payload.data
        }
      );
      builder.addCase(fetchDashboardMetrics.rejected, (state) => {
        
      });
      
    },
  });
  
export const { logOut } = dashboardSlice.actions;
  
export default dashboardSlice.reducer;