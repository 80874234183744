import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from "formik";
import { forgotValidator, resetPasswordValidator } from "../../validationSchema/validator";
import warning_icon from '../../assets/icons/warning.svg'
import logo from '../../assets/icons/logo-white.svg'
import arrow_left from '../../assets/icons/arrow-left.svg'
import checkmark from '../../assets/icons/checkmark.svg'
import OtpInput from 'react-otp-input';
import { useAppDispatch, useAppSelector} from '../../redux/store';
import { userForgotPassword, userResetPassword, validateForgotOTP } from '../../redux/Auth/authActions';


const ForgotPasswordPage = () => {

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmShown, setPasswordConfirmShown] = useState(false)
    const [otp, setOtp] = useState('');
    const [otpDisabled, setOtpDisabled] = useState(true);
    const [useremail, setUserEmail] = useState("");
    const [step, setStep] = useState(1);
    const dispatch = useAppDispatch();
    const {loading} = useAppSelector(state => state.auth)
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);
    const navigate = useNavigate()

      
  // Format minutes and seconds with leading zeros
  const formattedTime = `0${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

    // check if the otp's length is 6 with a use effect
    useEffect(() => {
        if (otp.length === 6) {
            // Perform action when the OTP length is 6
            setOtpDisabled(false)
        }
        else{
            setOtpDisabled(true)
        }
    }, [otp])
  
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };

      const toggleConfirmPasswordVisiblity = () => {
        setPasswordConfirmShown(passwordConfirmShown ? false : true)
       }
      
       const [passwordErrors, setErrors] = useState({
        minValueValidation: false,
        numberValidation: false,
        capitalLetterValidation: false,
        specialCharacterValidation: false,
      });

      const validatePassword = (password:string) => {
        setErrors({
          minValueValidation: password.length >= 6,
          numberValidation: /\d/.test(password),
          capitalLetterValidation: /[A-Z]/.test(password),
          specialCharacterValidation: /[^A-Za-z0-9]/.test(password),
        });
      };

    interface Values {
        email: string;
    }

    interface passwordType{
        password: string,
        confirm_password: string
    }


    const handleSubmit = (values: Values) => {
        // e.preventDefault()
        setUserEmail(values.email)
        dispatch(userForgotPassword(values)).then((res)=>{
            if(res?.type === "userForgotPassword/fulfilled"){
                setStep(2)
            }
        })
    };

    const handleVerifyOtp = () =>{
        console.log(otp)
        dispatch(validateForgotOTP({
            otp: otp
        })).then((res)=>{
            if(res?.type === "validateForgotOTP/fulfilled"){
                setStep(3)
            }
        })
    }

    const handleSetPassword = (values: passwordType) => {
        dispatch(userResetPassword({
            otp: otp,
            password: values.password
        })).then((res)=>{
            if(res?.type === "userResetPassword/fulfilled"){
                setStep(1)
                setOtp('')
                setUserEmail('')  
                navigate('/')
            }
        })
    }

    useEffect(() => {
        const interval = setInterval(() => {
          if (minutes === 0 && seconds === 0) {
            clearInterval(interval);
            // Handle timer expiration here
          } else if (seconds === 0) {
            setMinutes(minutes - 1);
            setSeconds(59);
          } else {
            setSeconds(seconds - 1);
          }
        }, 1000);
    
        return () => clearInterval(interval);
      }, [minutes, seconds]);

    return ( 
        <>
        <div className='hidden md:grid grid-cols-2'>
            <div className='bg-[#242424] px-6 py-8 h-full min-h-screen w-full'>
                <div>
                    <img src={logo} alt="logo" />
                </div>
                <div className='mt-8'>
                    <h6 className='font-medium text-2xl text-[#FFFFFF]'>Forgot password</h6>
                    <p className='font-sans text-[#F2F2F2] text-sm mt-2'>It happens to everyone. Don’t worry, we got you!</p>
                </div>


                <ol className="relative mt-8 ml-2 border-s-[4px] border-[#474747]">                  
                        <li className="mb-10 ms-6">            
                            <span className="absolute flex items-center justify-center w-[21px] h-[21px] -start-3 bg-[#FEFEFE] rounded-full">
                                <img src={checkmark} alt="check box" />
                            </span>
                            <h3 className="flex items-center text-[15px] font-sans-medium  text-[#FEFEFE]">Email Address</h3>
                        </li>
                        <li className="mb-10 ms-6">
                            <span className={`absolute flex items-center justify-center w-[21px] h-[21px] -start-3 ${step > 1 ? 'bg-[#FEFEFE]' : 'bg-[#474747]'} rounded-full`}>
                            {step > 1 && <img src={checkmark} alt="check box" />} 
                            </span>
                            <h3 className={`flex items-center font-sans-medium text-[15px] ${step > 1 ? 'text-[#FEFEFE]' : 'text-[#8E8E8E]'}`}>OTP Verification</h3>

                        </li>
                        <li className="mb-10 ms-6">
                        <span className={`absolute flex items-center justify-center w-[21px] h-[21px] -start-3 ${step > 2 ? 'bg-[#FEFEFE]' : 'bg-[#474747]'} rounded-full`}>
                            {step > 2 && <img src={checkmark} alt="check box" />} 
                            </span>
                            <h3 className={`flex items-center font-sans-medium text-[15px] ${step > 2 ? 'text-[#FEFEFE]' : 'text-[#8E8E8E]'}`}>New password</h3>

                        </li>
                    </ol>



            </div>
            <div className='py-8 px-12 h-full'>

                {/* <div className='flex justify-end'>
                    <p className='text-[#3A3A3A] font-sans text-[15px]'>Remember password? <Link to="/" className='text-[#7C44BD] font-sans-medium text-[15px] underline'>Log in</Link></p>
                </div> */}

               
                {/* Step 1 */}
              {step ===1 && 
               <div className='flex flex-col'>

                    <Link to="/" className='flex gap-2 items-center'>
                        <div className='flex justify-center items-center bg-[#EFEFEF] w-[40px] h-[40px] rounded-full'>
                            <img src={arrow_left} alt="arrow left" />
                        </div>
                        <div>
                            <p className='text-[#3A3A3A] text-sm font-sans'>Go back</p>
                        </div>
                    </Link>

                    <div className='mt-10'>
                        <h3 className='text-[#3A3A3A] font-familjen font-medium text-3xl'>Email Address</h3>
                    </div>

                  {/* form */}
                    <Formik
                    initialValues={{
                        email: "",
                    }}
                    validationSchema={forgotValidator}
                    onSubmit={(
                        values: Values,
                        { setSubmitting }: FormikHelpers<Values>
                    ) => {
                        handleSubmit(values);
                    }}
                    >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        isValid,
                        dirty,
                        touched,
                        errors,
                    }) => (
                        <Form className="py-8 font-sans" onSubmit={handleSubmit}>
                        
                        <div className='relative'>
                            <label
                            htmlFor="email"
                            className="block mb-3 text-[#303030] text-sm font-sans-medium"
                            >
                            Email Address
                            </label>
                            <div className='relative'>
                            <input
                            type="text"
                            name="email"
                            placeholder="e.g johndoe@gmail.com"
                            className={
                                touched.email && errors.email
                                ? "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm  text-[#121212] text-base focus:border-[#F74445] focus:outline-none rounded-[28px] border border-[#B92043] bg-[#FEECEC] py-4 px-6"
                                : "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6"
                            }
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />                      
                            {touched.email && errors.email ? ( <img src={warning_icon} alt="warning" className='w-[14px] h-[14px] absolute top-1/2 transform text-[#3A3A3A] text-sm font-medium -translate-y-1/2 right-6 cursor-pointer' />): null}
                        </div>

                            {touched.email && errors.email ? (
                            <div className="flex gap-1 mt-2 items-center">
                                <img src={warning_icon} alt="warning" className='w-[14px] h-[14px]' />
                                <small className="text-[#F74445] font-medium text-xs">
                                {touched.email && errors.email}
                                </small>
                            </div>
                            ) : null}
                        </div>

                        <div className="mt-10">
                            <button
                            type="submit"
                            disabled={!(isValid && dirty) || loading}
                            className="bg-[#7C44BD] 
                                        disabled:bg-[#E8E8E8] 
                                        rounded-[233px] w-full py-5 text-[#FEFEFE] font-sans-medium text-sm hover:bg-[#7C44BD] hover:bg-opacity-[0.9] transition-all"
                            >
                            Continue
                            </button>
                        </div>
                        </Form>
                    )}
                    </Formik>
                </div>
                }

                {/* Step 2 */}
              {step === 2 &&  
                <>
                
               <div className='h-full'>
                <div onClick={() => setStep(1)} className='flex gap-2 items-center'>
                        <div className='flex justify-center items-center bg-[#EFEFEF] w-[40px] h-[40px] rounded-full'>
                            <img src={arrow_left} alt="arrow left" />
                        </div>
                        <div>
                            <p className='text-[#3A3A3A] text-sm font-sans'>Go back</p>
                        </div>
                    </div>
                    <div className='py-10 flex flex-col justify-between h-full'>
                        <div>
                            <h3 className='text-[#3A3A3A] font-familjen font-medium text-3xl'>OTP verification</h3>
                            <p className='text-[#3A3A3A] text-sm font-sans mt-1'>A 6 digits code was sent to your email {useremail ? useremail : "user@email.com"}</p>


                            <div className='mt-8'>
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                inputType='tel'
                                shouldAutoFocus={true}
                                renderSeparator={<span className='mx-4 text-[#BDBDBD]'>-</span>}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={{ borderColor: "#E4E4E4", width: '60px', height: '60px', color: '#7C44BD', borderRadius: '10px', background: '#F8F8F8', borderWidth: '1px' }}
                                />
                            </div>
                        </div>

                    
                        <div className="">
                                <button
                                type="submit"
                                onClick={handleVerifyOtp}
                                disabled={otpDisabled || loading}
                                className="bg-[#7C44BD] 
                                            disabled:bg-[#E8E8E8] 
                                            rounded-[233px] w-full py-5 text-[#FEFEFE] font-sans-medium text-sm hover:bg-[#7C44BD] hover:bg-opacity-[0.9] transition-all"
                                >
                                Continue
                                </button>

                                {
                                minutes === 0 && seconds === 0 
                                    ?
                                    <div className='mt-2 flex justify-center'>
                                        <p className='text-[#3A3A3A] text-sm font-sans mt-1'>Resend code</p>
                                    </div>
                                    :
                                <div className='mt-2 flex justify-center'>
                                    <p className='text-[#3A3A3A] text-sm font-sans mt-1'>Didn’t receive code? <span className='text-[#949494]'>Resend in {formattedTime}</span></p>
                                </div>
                                }

                        </div>
                    </div>
                   
                </div>
                </>
                }

                {/* Step 3 */}
              {step === 3 &&  
              <div>

                    <div onClick={() => setStep(1)} className='flex gap-2 items-center cursor-pointer'>
                        <div className='flex justify-center items-center bg-[#EFEFEF] w-[40px] h-[40px] rounded-full'>
                            <img src={arrow_left} alt="arrow left" />
                        </div>
                        <div>
                            <p className='text-[#3A3A3A] text-sm font-sans'>Go back</p>
                        </div>
                    </div>
                    
                    <div className='mt-10'>
                        <h3 className='text-[#3A3A3A] font-familjen font-medium text-3xl'>Enter new password</h3>
                    </div>

                      {/* form */}
                      <Formik
                    initialValues={{
                        password: "",
                        confirm_password: ""
                    }}
                    validationSchema={resetPasswordValidator}
                    onSubmit={(
                        values: passwordType,
                        { setSubmitting }: FormikHelpers<passwordType>
                    ) => {
                        handleSetPassword(values);
                    }}
                    >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        isValid,
                        dirty
                    }) => (
                        <Form className="mt-10 font-sans" onSubmit={handleSubmit}>
                        

                        <div className="mt-8">
                            <label
                            htmlFor="password"
                            className="block mb-3 text-[#303030] text-sm font-sans-medium"
                            >
                            New Password
                            </label>
                            <div className="relative">
                            <input
                                type={passwordShown ? "text" : "password"}
                                name="password"
                                placeholder="Password"
                                className={
                                    touched.password && errors.password
                                    ? "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm  text-[#121212] text-base focus:border-[#F74445] focus:outline-none rounded-[28px] border border-[#F74445] bg-[#FEECEC] py-4 px-6"
                                    : "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6"
                                }
                                value={values.password}
                                onChange={(e) => {
                                    handleChange(e);
                                    validatePassword(e.currentTarget.value);
                                    }}
                                onBlur={handleBlur}
                            />
                            <div className='flex gap-1 items-center'> 
                                <p
                                    onClick={togglePasswordVisiblity}
                                    className={`absolute top-1/2 transform text-[#3A3A3A] text-sm font-sans-medium -translate-y-1/2 ${touched.password && errors.password ? 'right-12' : 'right-6'} cursor-pointer`}>
                                    {passwordShown ? 'Hide' : 'Show'}
                                </p>
                                {touched.password && errors.password ? ( <img src={warning_icon} alt="warning" className='w-[14px] h-[14px] absolute top-1/2 transform text-[#3A3A3A] text-sm font-medium -translate-y-1/2 right-6 cursor-pointer' />): null}
                            </div>
                            </div>
                            {touched.password && errors.password ? (
                            <div className="flex gap-1 items-center mt-2">
                                <img src={warning_icon} alt="warning" className="w-[14px] h-[14px]" />
                                <small className="text-[#F74445] font-medium text-xs">
                                {touched.password && errors.password}
                                </small>
                            </div>
                            ) : null}
                        </div>
                        <div className="mt-5">
                          <h6 className='text-[#6C6C6C] font-sans-medium text-sm'>Password Requirements</h6>
                             {Object.entries(passwordErrors).map(([key, value]) => {
                                return (
                                    <div className='flex gap-2 items-center mt-4'>
                                    <div className={`w-[10px] h-[10px] ${value ? 'bg-[#3A3A3A]' : 'bg-[#E5E5E5]'}  rounded-full`}></div>
                                    <div className='font-sans text-[#949494] text-xs'>
                                        {key === 'minValueValidation' && 'Must be a minimum of 6 characters'}
                                        {key === 'numberValidation' && 'Must have at least 1 number'}
                                        {key === 'capitalLetterValidation' && 'Must have at least 1 uppercase letter'}
                                        {key === 'specialCharacterValidation' && 'Must have at least 1 special character'}
                                    </div>
                                    </div>
                                )
                                })}
                         </div>

                        <div className="mt-8">
                            <label
                            htmlFor="confirm_password"
                            className="block mb-3 text-[#303030] text-sm font-sans-medium"
                            >
                            Re-enter Password
                            </label>
                            <div className="relative">
                            <input
                                type={passwordConfirmShown ? "text" : "password"}
                                name="confirm_password"
                                placeholder="Password"
                                className={
                                    touched.confirm_password && errors.confirm_password
                                    ? "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm  text-[#121212] text-base focus:border-[#F74445] focus:outline-none rounded-[28px] border border-[#F74445] bg-[#FEECEC] py-4 px-6"
                                    : "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6"
                                }
                                value={values.confirm_password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <div className='flex gap-1 items-center'> 
                                <p
                                    onClick={toggleConfirmPasswordVisiblity}
                                    className={`absolute top-1/2 transform text-[#3A3A3A] text-sm font-sans-medium -translate-y-1/2 ${touched.confirm_password && errors.confirm_password ? 'right-12' : 'right-6'} cursor-pointer`}>
                                    {passwordConfirmShown ? 'Hide' : 'Show'}
                                </p>
                                {touched.confirm_password && errors.confirm_password ? ( <img src={warning_icon} alt="warning" className='w-[14px] h-[14px] absolute top-1/2 transform text-[#3A3A3A] text-sm font-medium -translate-y-1/2 right-6 cursor-pointer' />): null}
                            </div>
                            </div>
                            {touched.confirm_password && errors.confirm_password ? (
                            <div className="flex gap-1 items-center mt-2">
                                <img src={warning_icon} alt="warning" className="w-[14px] h-[14px]" />
                                <small className="text-[#F74445] font-medium text-xs">
                                {touched.confirm_password && errors.confirm_password}
                                </small>
                            </div>
                            ) : null}
                        </div>

                        <div className="mt-10">
                            <button
                            type="submit"
                            disabled={!(isValid && dirty) || loading || !passwordErrors.capitalLetterValidation || !passwordErrors.minValueValidation || !passwordErrors.numberValidation || !passwordErrors.specialCharacterValidation}
                            className="bg-[#7C44BD] 
                                     disabled:bg-[#E8E8E8] 
                                        rounded-[233px] w-full py-5 text-[#FEFEFE] font-sans-medium text-sm hover:bg-[#7C44BD] hover:bg-opacity-[0.9] transition-all"
                            >
                            Submit
                            </button>
                         </div>

                         
                        </Form>
                    )}
                    </Formik>

                </div>
                }

            </div>

        </div>
        </>
     );
}
 
export default ForgotPasswordPage;