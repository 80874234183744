import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Auth/auth";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import dashboardReducer from './Dashboard/dashboard'


export const store = configureStore({
    reducer: {
      auth: authReducer,
      dashboard: dashboardReducer
    },
  });


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;