
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../config";
import axios from "axios";
import toast from "react-hot-toast";


const getToken = () => {
    const token = localStorage.getItem("token");
    return token;
  };

export const fetchDashboardMetrics = createAsyncThunk(
    "fetchDashboardMetrics",
   async (args, { rejectWithValue }) => {
      try {
        const res = await axios.get(`${apiUrl}dashboard/metrics`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getToken()}`
            }, 
         }
        );
        if (res.status === 200) {
          return res.data;
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error("Oops, an error occured fetching metrics",{
            style:{
              fontSize: 14,
              fontFamily: 'Sans-Regular'
            }
          });
          return rejectWithValue(error);
        } else {
            toast.error("Oops, an error occured fetching metrics",{
                style:{
                  fontSize: 14,
                  fontFamily: 'Sans-Regular'
                }
            });
          return rejectWithValue(error);
        }
      }
    }
  );