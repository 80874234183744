import React,{useEffect, useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from "formik";
import { loginValidator } from "../../validationSchema/validator";
import warning_icon from '../../assets/icons/warning.svg'
import logo from '../../assets/icons/logo-white.svg'
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { userLogin } from '../../redux/Auth/authActions';

const LoginPage = () => {

    const [passwordShown, setPasswordShown] = useState(false);
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch();
    const {loading, isAuth} = useAppSelector(state => state.auth)

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
      };
      
    interface Values {
        email: string;
        password: string;
      }

    const handleSubmit = (values: Values) => {
        // e.preventDefault()
        dispatch(userLogin(values))
       
    };

    useEffect(()=>{
        if(isAuth){
         navigate('/dashboard')
        }
    },[isAuth, navigate])
    

    return ( 
        <>
        <div className='hidden md:grid grid-cols-2'>
            <div className='bg-[#242424] flex flex-col justify-between px-8 py-10 h-full min-h-screen w-full'>
                <div>
                    <img src={logo} alt="logo" />
                </div>
                <div>
                    <div className='flex gap-2'>
                        <div className='w-[72px] bg-[#FEFEFE] h-[9px] rounded-[48px]'></div>
                        <div className='w-[72px] bg-[#555555] h-[9px] rounded-[48px]'></div>
                        <div className='w-[72px] bg-[#555555] h-[9px] rounded-[48px]'></div>
                    </div>
                    <div className='mt-4'>
                        <h6 className='font-medium text-xl text-[#FFFFFF]'>Unlock explosive growth</h6>
                        <p className='font-sans text-[#F2F2F2] text-sm mt-2 max-w-sm'>Earn points on every purchase, redeem for exclusive discounts and free products</p>
                    </div>
                </div>

            </div>
            <div className='py-10 px-12'>
                <div className='flex justify-end'>
                    <p className='text-[#3A3A3A] font-sans text-[15px]'>Don’t have an account yet? <Link to="/create-account" className='text-[#7C44BD] font-sans-medium text-[15px] underline'>Create account</Link></p>
                </div>


                <div className='flex flex-col justify-center h-full'>
                    <div className=''>
                        <h3 className='text-[#3A3A3A] font-familjen font-medium text-3xl'>Welcome back</h3>
                    </div>

                  {/* form */}
                    <Formik
                    initialValues={{
                        email: "",
                        password: "",
                    }}
                    validationSchema={loginValidator}
                    onSubmit={(
                        values: Values,
                        { setSubmitting }: FormikHelpers<Values>
                    ) => {
                        handleSubmit(values);
                    }}
                    >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                    }) => (
                        <Form className="mt-10 font-sans" onSubmit={handleSubmit}>
                        <div className='relative'>
                            <label
                            htmlFor="email"
                            className="block mb-3 text-[#303030] text-sm font-sans-medium"
                            >
                            Email Address
                            </label>
                            <div className='relative'>
                            <input
                            type="text"
                            name="email"
                            placeholder="e.g John Doe"
                            className={
                                touched.email && errors.email
                                ? "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm  text-[#121212] text-base focus:border-[#F74445] focus:outline-none rounded-[28px] border border-[#B92043] bg-[#FEECEC] py-4 px-6"
                                : "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6"
                            }
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />                      
                            {touched.email && errors.email ? ( <img src={warning_icon} alt="warning" className='w-[14px] h-[14px] absolute top-1/2 transform text-[#3A3A3A] text-sm font-medium -translate-y-1/2 right-6 cursor-pointer' />): null}
                        </div>

                            {touched.email && errors.email ? (
                            <div className="flex gap-1 mt-2 items-center">
                                <img src={warning_icon} alt="warning" className='w-[14px] h-[14px]' />
                                <small className="text-[#F74445] font-medium text-xs">
                                {touched.email && errors.email}
                                </small>
                            </div>
                            ) : null}
                        </div>

                        <div className="mt-8">
                            <label
                            htmlFor="password"
                            className="block mb-3 text-[#303030] text-sm font-sans-medium"
                            >
                            Password
                            </label>
                            <div className="relative">
                            <input
                                type={passwordShown ? "text" : "password"}
                                name="password"
                                placeholder="Password"
                                className={
                                    touched.password && errors.password
                                    ? "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm  text-[#121212] text-base focus:border-[#F74445] focus:outline-none rounded-[28px] border border-[#F74445] bg-[#FEECEC] py-4 px-6"
                                    : "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6"
                                }
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <div className='flex gap-1 items-center'> 
                                <p
                                    onClick={togglePasswordVisiblity}
                                    className={`absolute top-1/2 transform text-[#3A3A3A] text-sm font-sans-medium -translate-y-1/2 ${touched.password && errors.password ? 'right-12' : 'right-6'} cursor-pointer`}>
                                    {passwordShown ? 'Hide' : 'Show'}
                                </p>
                                {touched.password && errors.password ? ( <img src={warning_icon} alt="warning" className='w-[14px] h-[14px] absolute top-1/2 transform text-[#3A3A3A] text-sm font-medium -translate-y-1/2 right-6 cursor-pointer' />): null}
                            </div>
                            </div>
                            {touched.password && errors.password ? (
                            <div className="flex gap-1 items-center mt-2">
                                <img src={warning_icon} alt="warning" className="w-[14px] h-[14px]" />
                                <small className="text-[#F74445] font-medium text-xs">
                                {touched.password && errors.password}
                                </small>
                            </div>
                            ) : null}
                        </div>
                        <div className="mt-3">
                            <Link
                            to="/forgotpassword"
                            className="text-[#7C44BD] font-sans-medium text-sm"
                            >
                            Forgot your password?
                            </Link>
                        </div>

                        <div className="mt-10">
                            <button
                            type="submit"
                            disabled={loading}
                            className="bg-[#7C44BD] 
                                        disabled:bg-[#ABB6AC] 
                                        rounded-[233px] w-full py-5 text-[#FEFEFE] font-sans-medium text-sm hover:bg-[#7C44BD] hover:bg-opacity-[0.9] transition-all"
                            >
                            Log In
                            </button>
                        </div>
                        </Form>
                    )}
                    </Formik>
                </div>

            </div>

        </div>
        </>
     );
}
 
export default LoginPage;