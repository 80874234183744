import React from 'react'

const CustomersPage = () => {
    return ( 
        <>
            
        </>
     );
}
 
export default CustomersPage;