import React,{useEffect, useState} from 'react'
import hello_icon from '../../assets/icons/hello.svg'
import ranking_icon from '../../assets/icons/ranking.svg'
import flash_icon from '../../assets/icons/flash.svg'
import trendup from '../../assets/icons/trendup.svg'
import trenddown from '../../assets/icons/trenddown.svg'
import chevron_right from '../../assets/icons/Chevron_Right.svg'
import building1 from '../../assets/images/building-mid-2.svg'
import building2 from '../../assets/images/building-tall-1.svg'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { fetchDashboardMetrics } from '../../redux/Dashboard/dashboardActions'

const DashboardPage = () => {

    const [typeID, setTypeID] = useState(1);
    const salesType = [
        {
          id: 1,
          title: "1D",
          tag: "A day"
        },
        {
          id: 2,
          title: "7D",
          tag: "7 days"
        },
        {
          id: 3,
          title: "6M",
          tag: "6 months"
        },
        {
            id: 4,
            title: "1Y",
            tag: "I year"
          },
      ];
    const dispatch = useAppDispatch()
    const {metrics} = useAppSelector(state => state.dashboard)

    
      const toggleType = (id: number) => {
        setTypeID(id);
      }

      useEffect(()=>{
        dispatch(fetchDashboardMetrics())
      },[dispatch])

    return ( 
        <>
           
            <div className='mt-6 border flex border-[#EBEBEB] bg-[#FEFEFE] rounded-[24px] mx-6'>
                <div className='px-6 py-8 basis-[70%]'>
                    <h5 className='font-familjen text-[#121212] font-medium text-2xl'>Welcome to Rewrd!</h5>
                    <p className='text-[#3A3A3A] text-sm font-sans mt-2'>Here is what happened with your program in the last 30 days.</p>
                </div>
                <div className='bg-lines-bg bg-no-repeat bg-cover basis-[30%] flex justify-center items-center'>
                    <img src={hello_icon} alt="hello icon" />
                </div>
            </div>

            <div className='grid grid-cols-2 gap-6 mt-6 mx-6'>
                <div className='border border-[#EEEEEE] gap-4 bg-[#FEFEFE] rounded-[24px] py-6 px-6'>
                    <div className='bg-[#F06706] w-[60px] h-[60px] flex justify-center items-center rounded-full'>
                        <img src={ranking_icon} alt="ranking icon" className='w-[30px] h-[30px]' />
                    </div>
                    <p className='text-[#3A3A3A] font-sans text-sm mt-4'>Customers that earned points</p>
                    <h4 className='text-[#121212] font-familjen font-medium text-3xl mt-3'>{metrics ? metrics.customer_earned_points : 0}</h4>

                    <div className='bg-[#F7F7F7] rounded-xl py-4 px-4 flex gap-2 mt-5'>
                        <img src={metrics ? (metrics.customer_earned_points_change === "up" ? trendup : trenddown) : trenddown} alt="trend" />
                        <p className='text-[#949494] font-sans-medium text-sm'>{metrics ? (metrics.customer_earned_points_change === "up" ? "+" : "-") : "-"}  {metrics ? metrics.customer_earned_points_percentage_change: 0}% from last week</p>
                    </div>
                </div>

                <div className='border border-[#EEEEEE] gap-4 bg-[#FEFEFE] rounded-[24px] py-6 px-6'>
                    <div className='bg-[#7C44BD] w-[60px] h-[60px] flex justify-center items-center rounded-full'>
                        <img src={flash_icon} alt="flash icon" className='w-[30px] h-[30px]'  />
                    </div>
                    <p className='text-[#3A3A3A] font-sans text-sm mt-4'>Customers that redeemed points</p>
                    <h4 className='text-[#121212] font-familjen font-medium text-3xl mt-3'>{metrics ? metrics.customer_redemed_points: 0}</h4>

                    <div className='bg-[#F7F7F7] rounded-xl py-4 px-4 flex gap-2 mt-5'>
                        <img src={metrics ? (metrics.customer_redemed_points_change === "up" ? trendup : trenddown) : trenddown} alt="trend" />
                        <p className='text-[#949494] font-sans-medium text-sm'>{metrics ? (metrics.customer_redemed_points_change === "up" ? "+" : "-") : "-"} {metrics ? metrics.customer_redemed_points_percentage_change: 0}% from last week</p>
                    </div>
                </div>

            </div>
           

            <div className='mt-6 bg-[#07B6FF] relative overflow-hidden rounded-[20px] py-8 px-6 mx-6'>
                <div>
                    <h4 className='text-[#FEFEFE] font-familjen font-medium text-xl'>Earn Points</h4>
                    <p className='text-[#FEFEFE] font-sans leading-6 text-[15px] mt-3 max-w-sm'>
                    Create ways your customers can earn points when they join, share, and engage with your brand. 
                    </p>
                </div>

                <div className='flex gap-1 items-center mt-10 cursor-pointer'>
                    <p className='text-[#F8F8F8] font-sans-medium text-sm'>ADD WAYS TO EARN</p>
                    <img src={chevron_right} alt="chevron_right" />
                </div>

                <div className='absolute right-0 bottom-0'>
                    <img src={building1} alt="building" className='w-[200px] h-[200px]' />
                </div>
    
            </div>

            <div className='mt-6 bg-[#34AD6C] relative overflow-hidden rounded-[20px] py-8 px-6 mx-6'>
                <div>
                    <h4 className='text-[#FEFEFE] font-familjen font-medium text-xl'>Redeem points</h4>
                    <p className='text-[#FEFEFE] font-sans leading-6 text-[15px] mt-3 max-w-sm'>
                    Create rewards your customers can redeem with the points they’ve earned. 
                    </p>
                </div>

                <div className='flex gap-1 items-center mt-10 cursor-pointer'>
                    <p className='text-[#F8F8F8] font-sans-medium text-sm'>ADD WAYS TO REDEEM</p>
                    <img src={chevron_right} alt="chevron_right" />
                </div>

                <div className='absolute right-0 bottom-0'>
                    <img src={building2} alt="building" className='w-[200px] h-[200px]' />
                </div>
    
            </div>

         
            <div className='mt-6 border border-[#EEEEEE] bg-[#FEFEFE] rounded-[24px] mx-6 overflow-hidden'>
                <div className='bg-[#F9F9F9] flex justify-between items-center py-6 px-6'>
                    <div>
                        <h4 className='font-familjen text-[#303030] font-medium text-xl'>Sales influenced</h4>
                    </div>
                    <div>
                     <ul className="flex gap-4 items-center">
                        {salesType.map((item) => (
                        <li
                            key={item.id}
                            onClick={() => toggleType(item.id)}
                            className={
                            typeID === item.id
                                ? "border border-[#EAEAEA] text-[#FEFEFE] bg-[#303030] font-sans-medium rounded-[80px] px-5 py-2 cursor-pointer"
                                : "border border-[#EAEAEA] text-[#6C6C6C] font-sans-medium rounded-[20px] px-5 py-2 cursor-pointer"
                            }
                        >
                            <p
                            className={
                                typeID === item.id
                                ? "text-primary text-sm font-semibold"
                                : "text-[#96A397] text-sm font-semibold"
                            }
                            >
                            {item.title}
                            </p>
                        </li>
                        ))}
                    </ul>
                    </div>
                </div>
                
                <div className='px-6 py-4'>
                    <p>ffsfs</p>
                </div>
               
               
            </div>
         </>
     );
}
 
export default DashboardPage;