import React,{useState} from 'react'
import megaphone_icon from '../../../assets/images/megaphone-dynamic-color.svg'
import referralgroup from '../../../assets/icons/referralgroup.svg'
import referralusericon from '../../../assets/icons/referredusericon.svg'
import ranking_green from '../../../assets/icons/ranking-green.svg'
import edit_icon from '../../../assets/icons/edit-2.svg'
import trash_icon from '../../../assets/icons/trash.svg'
import add_icon from '../../../assets/icons/add.svg'

const ReferralsPage = () => {

    const [checked, setChecked] = useState(true);

    const handleChange = () => {
      setChecked(!checked);
    };

    return ( 
        <>
            <div className='mx-6 py-6'>
                <div className='bg-[#FEF0E6] relative overflow-hidden py-8 px-6'>
                    <div>
                        <h6 className='text-[#303030] font-sans-medium text-[15px]'>Program status is disabled</h6>
                        <p className='font-sans text-sm text-[#303030] mt-1'>Kindly turn on the program status by clicking on the switch above.</p>
                    </div>
                    <div className='absolute right-0 bottom-0'>
                        <img src={megaphone_icon} alt="megaphone icon" />
                    </div>
                </div>

                <div className='flex gap-4 items-center mt-6'>
                    <div className='bg-[#F6F6F6] w-[60px] h-[60px] relative flex justify-center items-center rounded-full overflow-hidden'>
                        <img src={referralgroup} alt="referralgroup" className='w-[45px] h-[45px] absolute bottom-0' />
                    </div>
                    <div>
                        <h6 className='text-[#121212] font-sans-medium text-lg'>Referring customer reward</h6>
                        <p className='text-[#3A3A3A] font-sans text-sm'>Create a way your referring customer can be rewarded</p>
                    </div>
                </div>

                <div className='bg-[#FAFAFA] border border-[#EDEDED] pb-2 rounded-[16px] mt-8'>
                    <div className=' border-b border-[#EDEDED] flex justify-between items-center py-5 px-6'>
                        <div>
                            <p className='text-[#303030] font-sans-medium text-lg'>Ways to be rewarded <span className='text-[#949494] font-sans-medium'>(2)</span></p>
                        </div>
                        <div>
                            <button className='bg-[#7C44BD] flex gap-1 justify-center items-center text-[#FEFEFE] font-sans-medium text-sm rounded-[233px] py-3 px-5 hover:bg-[#7C44BD] hover:bg-opacity-[0.9] transition-all'>
                                Create a way
                                <img src={add_icon} alt="add icon" />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col ">
                           {/* table header */}
                            <div className="flex flex-row bg-[#FEFEFE] border-b border-[#E9E9E9] font-sans-medium">
                                <div className="flex-1 text-[#949494] py-5 px-6   text-sm text-left">ICON & NAME</div>
                                <div className="flex-1 text-[#949494] py-5 px-6  text-sm text-left">REDEEMED BY</div>
                                <div className="flex-1 text-[#949494] py-5 px-6  text-sm text-left">STATUS</div>
                                <div className="flex-1 text-[#949494] py-5 px-6  text-sm text-left">ACTIONS</div>
                            </div>

                            {/* table rows */}
                            <div className="flex flex-col">
                                {/* row 1 */}
                                <div className="flex flex-row items-center bg-[#FEFEFE] border-b border-[#E9E9E9]">
                                    <div className="flex-1 py-5 px-6">
                                        <div className='flex gap-3 items-center'>
                                                <div className='bg-[#EBF7F0] w-[40px] h-[40px] rounded-full flex justify-center items-center'>
                                                <img src={ranking_green} alt="ranking green" className='w-[20px] h-[20px]' />
                                            </div>
                                            <p className='text-[#303030] font-sans-medium text-sm'>₦1,500 off coupon</p>
                                        </div>
                                    </div>
                                    <div className="flex-1 py-5 px-6">
                                        <p className='text-[#303030] font-sans-medium text-sm'>0 Users</p>
                                    </div>
                                   
                                    <div className="flex-1 py-5 px-6">
                                        <div className='flex gap-2 items-center'>
                                            <div className='border border-[#34AD6C] w-[14px] h-[14px] rounded-full flex justify-center items-center'>
                                                <div className='w-[10px] h-[10px] bg-[#34AD6C] rounded-full'></div>
                                            </div>
                                            <p className='text-[#303030] font-sans text-sm'>Active</p>
                                         </div>
                                    </div>
                                    <div className="flex-1 flex gap-2 py-5 px-6 ">
                                            <div className='w-[40px] h-[40px] cursor-pointer bg-[#F6F6F6] flex justify-center items-center rounded-full'>
                                            <img src={edit_icon} alt="edit icon" />
                                        </div>
                                        <div className='w-[40px] h-[40px] cursor-pointer bg-[#F6F6F6] flex justify-center items-center rounded-full'>
                                            <img src={trash_icon} alt="trash icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center bg-[#FEFEFE] border-b border-[#E9E9E9] rounded-br-[16px] rounded-bl-[16px] ">
                                    <div className="flex-1 py-5 px-6">
                                        <div className='flex gap-3 items-center'>
                                             <div className='bg-[#EBF7F0] w-[40px] h-[40px] rounded-full flex justify-center items-center'>
                                                <img src={ranking_green} alt="ranking green" className='w-[20px] h-[20px]' />
                                            </div>
                                            <p className='text-[#303030] font-sans-medium text-sm'>₦1,500 off coupon</p>
                                        </div>
                                    </div>
                                    <div className="flex-1 py-5 px-6">
                                        <p className='text-[#303030] font-sans-medium text-sm'>0 Users</p>
                                    </div>
                                   
                                    <div className="flex-1 py-5 px-6">
                                        <div className='flex gap-2 items-center'>
                                            <div className='border border-[#34AD6C] w-[14px] h-[14px] rounded-full flex justify-center items-center'>
                                                <div className='w-[10px] h-[10px] bg-[#34AD6C] rounded-full'></div>
                                            </div>
                                            <p className='text-[#303030] font-sans text-sm'>Active</p>
                                         </div>
                                    </div>
                                    <div className="flex-1 flex gap-2 py-5 px-6 ">
                                            <div className='w-[40px] h-[40px] cursor-pointer bg-[#F6F6F6] flex justify-center items-center rounded-full'>
                                            <img src={edit_icon} alt="edit icon" />
                                        </div>
                                        <div className='w-[40px] h-[40px] cursor-pointer bg-[#F6F6F6] flex justify-center items-center rounded-full'>
                                            <img src={trash_icon} alt="trash icon" />
                                        </div>
                                    </div>
                                </div>    
                        </div>
                    </div> 
                    
                </div>


                <div className='flex gap-4 items-center mt-6'>
                    <div className='bg-[#F6F6F6] w-[60px] h-[60px] relative flex justify-center items-center rounded-full overflow-hidden'>
                        <img src={referralusericon} alt="referralusericon" className='w-[45px] h-[45px] absolute bottom-0' />
                    </div>
                    <div>
                        <h6 className='text-[#121212] font-sans-medium text-lg'>Referring user reward</h6>
                        <p className='text-[#3A3A3A] font-sans text-sm'>Create a way your referred user can be rewarded</p>
                    </div>
                </div>


                <div className='bg-[#FAFAFA] border border-[#EDEDED] pb-2 rounded-[16px] mt-8'>
                    <div className=' border-b border-[#EDEDED] flex justify-between items-center py-5 px-6'>
                        <div>
                            <p className='text-[#303030] font-sans-medium text-lg'>Ways to be rewarded <span className='text-[#949494] font-sans-medium'>(2)</span></p>
                        </div>
                        <div>
                            <button className='bg-[#7C44BD] flex gap-1 justify-center items-center text-[#FEFEFE] font-sans-medium text-sm rounded-[233px] py-3 px-5 hover:bg-[#7C44BD] hover:bg-opacity-[0.9] transition-all'>
                                Create a way
                                <img src={add_icon} alt="add icon" />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col ">
                           {/* table header */}
                            <div className="flex flex-row bg-[#FEFEFE] border-b border-[#E9E9E9] font-sans-medium">
                                <div className="flex-1 text-[#949494] py-5 px-6   text-sm text-left">ICON & NAME</div>
                                <div className="flex-1 text-[#949494] py-5 px-6  text-sm text-left">REDEEMED BY</div>
                                <div className="flex-1 text-[#949494] py-5 px-6  text-sm text-left">STATUS</div>
                                <div className="flex-1 text-[#949494] py-5 px-6  text-sm text-left">ACTIONS</div>
                            </div>

                            {/* table rows */}
                            <div className="flex flex-col">
                                {/* row 1 */}
                                <div className="flex flex-row items-center bg-[#FEFEFE] border-b border-[#E9E9E9]">
                                    <div className="flex-1 py-5 px-6">
                                        <div className='flex gap-3 items-center'>
                                                <div className='bg-[#EBF7F0] w-[40px] h-[40px] rounded-full flex justify-center items-center'>
                                                <img src={ranking_green} alt="ranking green" className='w-[20px] h-[20px]' />
                                            </div>
                                            <p className='text-[#303030] font-sans-medium text-sm'>₦1,500 off coupon</p>
                                        </div>
                                    </div>
                                    <div className="flex-1 py-5 px-6">
                                        <p className='text-[#303030] font-sans-medium text-sm'>0 Users</p>
                                    </div>
                                   
                                    <div className="flex-1 py-5 px-6">
                                        <div className='flex gap-2 items-center'>
                                            <div className='border border-[#34AD6C] w-[14px] h-[14px] rounded-full flex justify-center items-center'>
                                                <div className='w-[10px] h-[10px] bg-[#34AD6C] rounded-full'></div>
                                            </div>
                                            <p className='text-[#303030] font-sans text-sm'>Active</p>
                                         </div>
                                    </div>
                                    <div className="flex-1 flex gap-2 py-5 px-6 ">
                                            <div className='w-[40px] h-[40px] cursor-pointer bg-[#F6F6F6] flex justify-center items-center rounded-full'>
                                            <img src={edit_icon} alt="edit icon" />
                                        </div>
                                        <div className='w-[40px] h-[40px] cursor-pointer bg-[#F6F6F6] flex justify-center items-center rounded-full'>
                                            <img src={trash_icon} alt="trash icon" />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row items-center bg-[#FEFEFE] border-b border-[#E9E9E9] rounded-br-[16px] rounded-bl-[16px] ">
                                    <div className="flex-1 py-5 px-6">
                                        <div className='flex gap-3 items-center'>
                                             <div className='bg-[#EBF7F0] w-[40px] h-[40px] rounded-full flex justify-center items-center'>
                                                <img src={ranking_green} alt="ranking green" className='w-[20px] h-[20px]' />
                                            </div>
                                            <p className='text-[#303030] font-sans-medium text-sm'>₦1,500 off coupon</p>
                                        </div>
                                    </div>
                                    <div className="flex-1 py-5 px-6">
                                        <p className='text-[#303030] font-sans-medium text-sm'>0 Users</p>
                                    </div>
                                   
                                    <div className="flex-1 py-5 px-6">
                                        <div className='flex gap-2 items-center'>
                                            <div className='border border-[#34AD6C] w-[14px] h-[14px] rounded-full flex justify-center items-center'>
                                                <div className='w-[10px] h-[10px] bg-[#34AD6C] rounded-full'></div>
                                            </div>
                                            <p className='text-[#303030] font-sans text-sm'>Active</p>
                                         </div>
                                    </div>
                                    <div className="flex-1 flex gap-2 py-5 px-6 ">
                                            <div className='w-[40px] h-[40px] cursor-pointer bg-[#F6F6F6] flex justify-center items-center rounded-full'>
                                            <img src={edit_icon} alt="edit icon" />
                                        </div>
                                        <div className='w-[40px] h-[40px] cursor-pointer bg-[#F6F6F6] flex justify-center items-center rounded-full'>
                                            <img src={trash_icon} alt="trash icon" />
                                        </div>
                                    </div>
                                </div>    
                        </div>
                    </div> 
                    
                </div>

                {/* landing page branding */}
                <div className='mt-8 bg-[#FAFAFA] pb-2 border border-[#EDEDED] overflow-hidden rounded-[24px]'>
                    <div className='border-b border-[#EDEDED] bg-[#FEFEFE] py-5 px-6'>
                        <p className='text-[#3A3A3A] font-sans-medium text-lg'>Landing Page</p>
                        <p className='text-[#3A3A3A] text-sm mt-1 font-sans'>Manage when customers' points expire. </p>
                    </div>
                    <div className='py-5 px-6 bg-[#FEFEFE] rounded-br-[24px] rounded-bl-[24px] border-b border-[#E9E9E9]'>
                        <p className='text-[#303030] font-sans-medium text-sm'>URL</p>
                        <div className='relative mt-2'>
                            <input
                            type="text"
                            name="plural"
                            placeholder="e.g https://example.com"
                            className='appearance-none mt-1 font-sans w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6'
                            />       
                            <small className='text-[#6C6C6C] text-xs font-sans'>By default, all your referred customers will be sent to your homepage.</small>               
                        </div>
                     </div>

                </div>

                  {/* points expiration */}
                  <div className='mt-8 border bg-[#FAFAFA] pb-2  border-[#EEEEEE] rounded-[16px] overflow-hidden'>
                    <div className='flex justify-between items-center py-5 px-6 border-b bg-[#FEFEFE] border-[#EDEDED]'>
                        <div>
                            <p className='text-[#3A3A3A] font-sans-medium text-lg'>Points expiration</p>
                            <p className='text-[#3A3A3A] text-sm mt-1 font-sans'>Manage when customers' points expire. </p>
                        </div>
                        <div className='flex gap-2 items-center'>
                        <p className='text-[#6C6C6C] font-sans-medium text-sm'>Active</p>
                            <label className="flex items-center cursor-pointer">
                                <input type="checkbox" value="" className="sr-only peer" 
                                    checked={checked}
                                    onChange={handleChange}
                                    />
                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-0  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#00A86B]"></div>
                            </label>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-6 py-5 px-6 bg-[#FEFEFE] rounded-br-[24px] rounded-bl-[24px] border-b border-[#E9E9E9]'>
                        <div>
                            <p className='text-[#303030] font-sans-medium text-sm'>Expiration period</p>
                            <div className='relative mt-2'>
                                <input
                                type="text"
                                name="plural"
                                placeholder="Select expiration period"
                                className='appearance-none mt-1 font-sans w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6'
                                />       
                                <small className='text-[#6C6C6C] text-xs font-sans'>Points will expire 6 months after the day of activation</small>               
                           </div>
                        </div>
                        <div>
                          <p className='text-[#303030] font-sans-medium text-sm'>Reactivation email</p>

                          <div className='relative mt-2'>
                                <input
                                type="text"
                                name="singular"
                                placeholder="Select number of days"
                                className='appearance-none font-sans mt-1 w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6'
                                />   
                               <small className='text-[#6C6C6C] text-xs font-sans'>Notification email will be sent ** days prior to expiration date</small>                                  
                           </div>

                        </div>
                        <div>
                          <p className='text-[#303030] font-sans-medium text-sm'>Last chance email</p>

                          <div className='relative mt-2'>
                                <input
                                type="text"
                                name="singular"
                                placeholder="Select number of days"
                                className='appearance-none font-sans mt-1 w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6'
                                />   
                               <small className='text-[#6C6C6C] text-xs font-sans'>Notification email will be sent 1 day prior to expiration date</small>                                  
                           </div>

                        </div>
                    </div>
                   
                </div>

            </div>
        </>
     );
}
 
export default ReferralsPage;