import React,{useState} from 'react'
import bell from '../../assets/icons/notification.svg'
import magicpen from '../../assets/icons/magicpen.svg'
import home_icon from '../../assets/icons/home.svg'
import brush_icon from '../../assets/icons/brush.svg'
import command_icon from '../../assets/icons/command.svg'
import help_icon from '../../assets/icons/message-question.svg'
import settings_icon from '../../assets/icons/setting-2.svg'
import customer_icon from '../../assets/icons/profile-2user.svg'
import rocket_icon from '../../assets/icons/Rocket.svg'
// import people_icon from '../../assets/icons/people.svg'
import flash_icon from '../../assets/icons/flash-icon.svg'
import chart_icon from '../../assets/icons/chart.svg'
import chevron_down from '../../assets/icons/Chevron_down.svg'
import chevron_up from '../../assets/icons/Chevron_up.svg'
import ellipse from '../../assets/icons/Ellipse 2.svg'
import unfold_more from '../../assets/icons/Unfold_More.svg'
import logo from '../../assets/icons/logo.svg'
import { NavLink, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../redux/store'

type sidebarType = {
    title: string;
    children: React.ReactNode;
  };

const Sidebar = ({ title,children }: sidebarType) => {

    let location = useLocation();
    const [isOpen, setIsOpen] = useState(false)

    const [checked, setChecked] = useState(false);
    const {profile} = useAppSelector(state => state.auth)
    const handleChange = () => {
      setChecked(!checked);
    };

    const toggleDropdown = () =>{
        setIsOpen(!isOpen)  
    }

    return ( 
        <>
        <div className="hidden lg:flex h-full min-h-screen">
            <div className="md:w-64 flex flex-col justify-between min-h-screen bg-[#1A1A1B] py-8 flex-shrink-0 overflow-y-scroll">
                <div>
                    <div className='px-5'>
                        <img src={logo} alt="logo" />
                    </div>

                    <div className='mt-8 flex flex-col justify-between'>
                        <section className='px-5'>
                        <p className='uppercase text-[#5E5E5E] text-sm font-sans'>MENU</p>
                            <NavLink
                                to="/dashboard"
                                className={
                                location.pathname === "/dashboard"
                                    ? "flex gap-4 items-center mt-5"
                                    : "flex gap-4 items-center mt-5"
                                }
                            >
                                <img src={home_icon} alt="home icon" />
                                <p className={`text-sm ${location.pathname === "/dashboard" ? "text-[#FEFEFE]":"text-[#8E8E8E]" } font-sans-medium capitalize hover:text-[#fefefe]`}>
                                    Dashboard
                                    </p>
                            </NavLink>

                            <div
                                className="mt-8"
                            >   
                                <div onClick={toggleDropdown} className='flex justify-between items-center cursor-pointer'>
                                    <div className='flex gap-4'>
                                    <img src={flash_icon} alt="flash icon" />
                                    <p className={`text-sm ${location.pathname === "/program" ? "text-[#FEFEFE]":"text-[#8E8E8E]" } font-sans-medium capitalize`}>
                                    Program
                                    </p>
                                </div>
                                    <div>
                                        <img src={isOpen ? chevron_up : chevron_down} alt="chevron" />
                                    </div>
                                </div>
                            {isOpen && 
                                <ul className='mt-5'>
                                <li className='pl-9 w-full'>
                                    <NavLink
                                        to="/points"
                                        className={
                                            location.pathname === "/points"
                                                ? "text-sm text-[#FEFEFE] font-sans-medium capitalize hover:text-[#fefefe]"
                                                : "text-sm text-[#8E8E8E] font-sans-medium capitalize hover:text-[#fefefe]"
                                            }
                                    
                                    >
                                        Points    
                                    </NavLink>
                                </li>
                                <li className='pl-9 w-full mt-5'>
                                    <NavLink
                                        to="/referrals"
                                        className={
                                            location.pathname === "/referrals"
                                                ? "text-sm text-[#FEFEFE] font-sans-medium capitalize hover:text-[#fefefe]"
                                                : "text-sm text-[#8E8E8E] font-sans-medium capitalize hover:text-[#fefefe]"
                                            }
                                    >
                                        Referrals    
                                    </NavLink>
                                </li>
                                
                                </ul>
                                }
                                
                            </div>

                            <NavLink
                                to="/customers"
                                className={
                                location.pathname === "/customers"
                                    ? "flex gap-4 items-center mt-8 text-primary"
                                    : "flex gap-4 items-cente mt-8 text-white"
                                }
                            >
                                <img src={customer_icon} alt="customer icon" />
                                <p className={`text-sm ${location.pathname === "/customers" ? "text-[#FEFEFE]":"text-[#8E8E8E]" } font-sans-medium capitalize hover:text-[#fefefe] transition-all`}>
                                Customers
                                </p>
                            </NavLink>

                            <NavLink
                                to="/activity"
                                className={
                                location.pathname === "/activity"
                                    ? "flex gap-4 items-center mt-8 text-primary"
                                    : "flex gap-4 items-cente mt-8 text-white"
                                }
                            >
                                <img src={chart_icon} alt="chart icon" />
                                <p className={`text-sm ${location.pathname === "/activity" ? "text-[#FEFEFE]":"text-[#8E8E8E]" } font-sans-medium capitalize hover:text-[#fefefe] transition-all`}>
                                Activity
                                </p>
                            </NavLink>


                            <p className='uppercase text-[#5E5E5E] text-sm font-sans mt-8'>TOOLS</p>


                            <NavLink
                                to="/branding"
                                className={
                                location.pathname === "/branding"
                                    ? "flex gap-4 items-center mt-5 text-primary"
                                    : "flex gap-4 items-center mt-5 text-white"
                                }
                            >
                                <img src={brush_icon} alt="branding icon" />
                                <p className={`text-sm ${location.pathname === "/branding" ? "text-[#FEFEFE]":"text-[#8E8E8E]" } font-sans-medium capitalize hover:text-[#fefefe]`}>
                                Branding
                                </p>
                            </NavLink>
                            <NavLink
                                to="/integrations"
                                className={
                                location.pathname === "/integrations"
                                    ? "flex gap-4 items-center mt-8 text-primary"
                                    : "flex gap-4 items-center mt-8 text-white"
                                }
                            >
                                <img src={command_icon} alt="integrations icon" />
                                <p className={`text-sm ${location.pathname === "/customers" ? "text-[#FEFEFE]":"text-[#8E8E8E]" } font-sans-medium capitalize hover:text-[#fefefe]`}>
                                Integrations
                                </p>
                            </NavLink>

                        </section>
                    </div>

                </div>
                   
                <div>  
                    <div className='flex flex-col px-5'>
                        <div className='bg-rewrdblue  pt-4 px-4 rounded-tr-[12px] rounded-tl-[12px]'> 
                            <div className='bg-[#EBF7F0] py-4 px-4 rounded-tr-[12px] rounded-tl-[12px]'>
                                <div className='flex justify-between items-center'>
                                    <p className="text-[#121212] text-sm font-sans-medium">Basic plan</p>
                                    <div className='border border-[#CBDBD2] bg-[#DAE9E0] rounded-[64px] flex gap-1 py-1 px-2'>
                                        <img src={magicpen} alt="magic pen" />
                                        <p className="text-[#303030] text-sm font-sans">6/10</p>
                                    </div>
                                </div>
                                <div className='bg-[#d5e3db] h-[12px] rounded-[64px] w-full mt-4'></div>
                                <div className='bg-[#d5e3db] h-[12px] rounded-[64px] w-full mt-2'></div>
                                <div className='bg-[#d5e3db] h-[12px] rounded-[64px] w-full mt-2'></div>
                            </div>
                        </div>
                        <div className='bg-[#29292B]  py-4 px-4 rounded-br-[12px] rounded-bl-[12px]'>
                            <div className='flex gap-1 items-center'>
                                <p className='text-[#FEFEFE] text-[15px] font-sans'>Your trial ends in 9 days</p>
                                <img src={rocket_icon} alt="rocket icon" />
                            </div>
                            <p className='text-[#B4B4B4] font-sans text-xs mt-1'><span className=' underline cursor-pointer text-[#FEFEFE]'>Upgrade</span> your plan to continue</p>
                        </div>
                    </div>
                        
                    <div className='bg-[#282828] w-full h-[1px] mt-8'></div>
                            
                    <div className='px-5'>
                    <NavLink
                        to="/help"
                        className={
                        location.pathname === "/help"
                            ? "flex gap-4 items-center mt-6 text-primary"
                            : "flex gap-4 items-center mt-6 text-white"
                        }
                    >
                        <img src={help_icon} alt="help icon" />
                        <p className={`text-sm ${location.pathname === "/help" ? "text-[#FEFEFE]":"text-[#8E8E8E]" } font-sans-medium capitalize hover:text-[#fefefe]`}>
                        Help
                        </p>
                    </NavLink>

                    <NavLink
                        to="/settings"
                        className={
                        location.pathname === "/settings"
                            ? "flex gap-4 items-center mt-8 text-primary"
                            : "flex gap-4 items-center mt-8 text-white"
                        }
                    >
                        <img src={settings_icon} alt="settings icon" />
                        <p className={`text-sm ${location.pathname === "/settings" ? "text-[#FEFEFE]":"text-[#8E8E8E]" } font-sans-medium capitalize hover:text-[#fefefe]`}>
                        Settings
                        </p>
                    </NavLink>
                    </div>

                </div>
                
            </div>

            <div className="flex-grow flex-auto flex-shrink overflow-y-scroll">
                {/* navbar section and pages flow */}
                <div className="flex border-b border-[#EBEBEB] bg-[#FEFEFE] py-6 px-4 md:px-6 justify-between items-center">
                    <div>
                        <p className="text-lg font-sans-medium text-[#303030]">
                        {title}
                        </p>
                    </div>
                    <div className="flex gap-6 items-center">
                        <div className='flex gap-3 items-center'>
                            <div>
                            <p className='text-[#3A3A3A] font-sans text-sm'>Program status</p>
                            </div>
                            <div>
                            <label className="flex items-center cursor-pointer">
                                <input type="checkbox" value="" className="sr-only peer" 
                                  checked={checked}
                                  onChange={handleChange}
                                 />
                                <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-0  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-[#00A86B]"></div>
                            </label>
                            </div>
                        </div>
                        <div className="cursor-pointer flex justify-center items-center bg-[#F5F5F5] w-[40px] h-[40px] rounded-full">
                            <img src={bell} alt="bell" />
                        </div>
                        <div className='bg-[#B9B9B9] h-[24px] w-[1px]'></div>
                        
                        {/* company's logo and badge */}
                        <div className='flex gap-2 justify-between min-w-[200px] py-3 px-4 items-center bg-[#121212] rounded-[56px]'>
                            <div className='flex gap-2 flex-1 items-center'>
                                <div className='w-[32px] h-[32px] rounded-full'>
                                    {
                                        profile && profile.business_logo ?
                                        <img src={profile.business_logo} alt="logo"  className='w-[32px] h-[32px] rounded-full' />
                                        :
                                        <img src={ellipse} alt="logo" />
                                    }
                                </div>
                                <div>
                                    <p className="text-[#F8F8F8] text-sm font-sans-medium">{profile && profile.business_name ? profile.full_name : 'Test User'}</p>
                                </div>
                            </div>
                           
                            <div className='cursor-pointer'>
                                <img src={unfold_more} alt="unfold_more" />
                            </div>
                        </div>

                    </div>
                </div>
                 <div className="mb-8">{children}</div>
            </div>
        </div>
        </>
     );
}
 
export default Sidebar;