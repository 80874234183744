import React,{useState, useEffect, useRef} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from "formik";
import { onboardMerchantValidator } from "../../validationSchema/validator";
import warning_icon from '../../assets/icons/warning.svg'
import caret_down from '../../assets/icons/Caret_Down.svg'
import nigeria from '../../assets/icons/nigeria.svg'
import logo from '../../assets/icons/logo-white.svg'
import checkmark from '../../assets/icons/checkmark.svg'
import building_tall from '../../assets/icons/building-tall-blue.svg'
import OtpInput from 'react-otp-input';
import arrow_left from '../../assets/icons/arrow-left.svg'
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { completeOnboardProcess, onboardMerchant, uploadBusinessLogo, verifyMerchantEmail } from '../../redux/Auth/authActions';
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { clearEncryptedData } from '../../redux/Auth/auth';

const SignUpPage = () => {

    const [passwordShown, setPasswordShown] = useState(false);
    const fileRef = useRef<HTMLInputElement>(null);
    const selectRef = useRef<HTMLSelectElement>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [otp, setOtp] = useState('');
    const [step,setStep] = useState(1);
    const [otpDisabled, setOtpDisabled] = useState(true);
    const [useremail, setUserEmail] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [businessIndustry, setBusinessIndustry] = useState("");
    const dispatch = useAppDispatch()
    const {loading, logoUrl} = useAppSelector(state => state.auth)
    const [minutes, setMinutes] = useState(1);
    const [seconds, setSeconds] = useState(0);
    const [imgLoader, setImgLoader] = useState(false)
    const navigate = useNavigate()
     // Format minutes and seconds with leading zeros
  const formattedTime = `0${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const [passwordErrors, setErrors] = useState({
        minValueValidation: false,
        numberValidation: false,
        capitalLetterValidation: false,
        specialCharacterValidation: false,
      });

      const validatePassword = (password:string) => {
        setErrors({
          minValueValidation: password.length >= 6,
          numberValidation: /\d/.test(password),
          capitalLetterValidation: /[A-Z]/.test(password),
          specialCharacterValidation: /[^A-Za-z0-9]/.test(password),
        });
      };

      const handleSelectClick = () =>{
        if (selectRef.current) {
            selectRef.current.click(); // Programmatically trigger click event of select element
          }
      }


    // check if the otp's length is 6 with a use effect
    useEffect(() => {
        if (otp.length === 6) {
            // Perform action when the OTP length is 6
            setOtpDisabled(false)
        }
        else{
            setOtpDisabled(true)
        }
    }, [otp])
      
    interface Values {
        email: string;
        password: string;
        phone_number: string;
        full_name: string;
        acceptTerms: string
    }

    const handleSubmit = (values: Values) => {
    // e.preventDefault()
        setUserEmail(values.email)
        dispatch(onboardMerchant({
            email: values.email,
            password: values.password,
            phone_number: values.phone_number,
            full_name: values.full_name
        })).then((res)=>{
            if(res?.type === "onboardMerchant/fulfilled"){
                setStep(2)
            }
        })
    };

    const handleVerifyMerchant = () =>{
        dispatch(verifyMerchantEmail({
            otp: otp    
        })).then((res) =>{
            if(res?.type === "verifyMerchantEmail/fulfilled"){
                setStep(3)
            }
        })   
    }


    const changedHandler = () => {
        if (fileRef.current?.files && fileRef.current.files.length > 0) {
            const selectedFile = fileRef.current.files[0];
            // Here you can perform any action with the selected file,
            // like uploading it to a server using fetch or a library like axios.


            if (!['image/jpeg', 'image/png'].includes(selectedFile.type)) {
                toast.error("Please select a JPG or PNG image.",{
                    style:{
                      fontSize: 14,
                      fontFamily: 'Sans-Regular'
                    }
                  });
                return;
            }
        
            if (selectedFile.size > 6 * 1024 * 1024) {
                toast.error("File size exceeds 6MB.",{
                    style:{
                      fontSize: 14,
                      fontFamily: 'Sans-Regular'
                    }
                  });
                return;
            }
        
            const image = new Image();
            image.onload = () => {
                if (image.width < 800 || image.height < 800) {
                toast.error("Image dimensions must be at least 800x800 pixels.",{
                    style:{
                      fontSize: 14,
                      fontFamily: 'Sans-Regular'
                    }
                  });
                return;
                }
                setPreviewUrl(URL.createObjectURL(selectedFile));
                console.log('Selected file:', selectedFile);
                setImgLoader(true)
                dispatch(uploadBusinessLogo({
                    file: selectedFile
                })).then((res)=>{
                    if(res?.type === "uploadBusinessLogo/fulfilled"){
                        setImgLoader(false)
                    }
                    else{
                        setImgLoader(false)
                    }
                })
            };
            image.src = URL.createObjectURL(selectedFile);


        } else {
            console.log('No file selected.');
            setPreviewUrl(null);
        }
    }

    const handleCompleteReg = () =>{
        dispatch(completeOnboardProcess({
            business_name: businessName,
            business_industry: businessIndustry
        })).then((res)=>{
            if(res?.type === "completeOnboardProcess/fulfilled"){
                setStep(1)
                setOtp('')
                setUserEmail('')
                dispatch(clearEncryptedData())
                navigate('/dashboard')
            }
        })
    }


    useEffect(() => {
        const interval = setInterval(() => {
          if (minutes === 0 && seconds === 0) {
            clearInterval(interval);
            // Handle timer expiration here
          } else if (seconds === 0) {
            setMinutes(minutes - 1);
            setSeconds(59);
          } else {
            setSeconds(seconds - 1);
          }
        }, 1000);
    
        return () => clearInterval(interval);
      }, [minutes, seconds]);

    return ( 
        <>
        <div className='hidden md:grid grid-cols-2'>
            <div className='bg-[#242424] flex flex-col justify-between px-8 py-10 h-full min-h-screen w-full'>
                <div>
                    <img src={logo} alt="logo" />

                    <div className='mt-10'>
                        <h6 className='font-medium text-2xl text-[#FFFFFF]'>Get started</h6>
                        <p className='font-sans text-[#F2F2F2] text-sm mt-2'>This will only take few seconds to complete.</p>
                    </div>



                    <ol className="relative mt-8 ml-2 border-s-[4px] border-[#474747]">                  
                        <li className="mb-10 ms-6">            
                            <span className="absolute flex items-center justify-center w-[21px] h-[21px] -start-3 bg-[#FEFEFE] rounded-full">
                                <img src={checkmark} alt="check box" />
                            </span>
                            <h3 className="flex items-center text-[15px] font-sans-medium  text-[#FEFEFE]">Personal information</h3>
                        </li>
                        <li className="mb-10 ms-6">
                            <span className={`absolute flex items-center justify-center w-[21px] h-[21px] -start-3 ${step > 1 ? 'bg-[#FEFEFE]' : 'bg-[#474747]'} rounded-full`}>
                            {step > 1 && <img src={checkmark} alt="check box" />} 
                            </span>
                            <h3 className={`flex items-center font-sans-medium text-[15px] ${step > 1 ? 'text-[#FEFEFE]' : 'text-[#8E8E8E]'}`}>Email verification</h3>

                        </li>
                        <li className="mb-10 ms-6">
                        <span className={`absolute flex items-center justify-center w-[21px] h-[21px] -start-3 ${step > 2 ? 'bg-[#FEFEFE]' : 'bg-[#474747]'} rounded-full`}>
                            {step > 2 && <img src={checkmark} alt="check box" />} 
                            </span>
                            <h3 className={`flex items-center font-sans-medium text-[15px] ${step > 2 ? 'text-[#FEFEFE]' : 'text-[#8E8E8E]'}`}>Business Information</h3>

                        </li>
                    </ol>

                </div>
                <div>
                    <div className='flex gap-2'>
                        <div className='w-[72px] bg-[#FEFEFE] h-[9px] rounded-[48px]'></div>
                        <div className='w-[72px] bg-[#555555] h-[9px] rounded-[48px]'></div>
                        <div className='w-[72px] bg-[#555555] h-[9px] rounded-[48px]'></div>
                    </div>
                    <div className='mt-4'>
                        <h6 className='font-medium text-xl text-[#FFFFFF]'>Unlock explosive growth</h6>
                        <p className='font-sans text-[#F2F2F2] text-sm mt-2 max-w-sm'>Earn points on every purchase, redeem for exclusive discounts and free products</p>
                    </div>
                </div>

            </div>
            <div className='py-10 px-12'>
                {step === 1 && <div className='flex justify-end'>
                    <p className='text-[#3A3A3A] font-sans text-[15px]'>Already have an account? <Link to="/" className='text-[#7C44BD] font-sans-medium text-[15px] underline'>Log in</Link></p>
                </div>
                }


                {/* step 1 */}
               {
                 step === 1 &&
                <div className='mt-12'>
                    <div>
                        <h3 className='text-[#3A3A3A] font-familjen font-medium text-3xl'>Personal information</h3>
                    </div>

                  {/* form */}
                    <Formik
                    initialValues={{
                        email: "",
                        password: "",
                        full_name: "",
                        phone_number: "",
                        acceptTerms: "false",
                    }}
                    validationSchema={onboardMerchantValidator}
                    onSubmit={(
                        values: Values,
                        { setSubmitting }: FormikHelpers<Values>
                    ) => {
                        handleSubmit(values);
                    }}
                    >
                    {({
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        isValid,
                        dirty,
                        setFieldValue
                    }) => (
                        <Form className="mt-10 font-sans" onSubmit={handleSubmit}>
                        
                        <div className='relative'>
                            <label
                            htmlFor="full_name"
                            className="block mb-3 text-[#303030] text-sm font-sans-medium"
                            >
                            Full Name
                            </label>
                            <div className='relative'>
                            <input
                            type="text"
                            name="full_name"
                            placeholder="e.g John Doe"
                            className={
                                touched.full_name && errors.full_name
                                ? "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm  text-[#121212] text-base focus:border-[#F74445] focus:outline-none rounded-[28px] border border-[#B92043] bg-[#FEECEC] py-4 px-6"
                                : "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6"
                            }
                            value={values.full_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />                      
                            {touched.full_name && errors.full_name ? ( <img src={warning_icon} alt="warning" className='w-[14px] h-[14px] absolute top-1/2 transform text-[#3A3A3A] text-sm font-medium -translate-y-1/2 right-6 cursor-pointer' />): null}
                        </div>

                            {touched.full_name && errors.full_name ? (
                            <div className="flex gap-1 mt-2 items-center">
                                <img src={warning_icon} alt="warning" className='w-[14px] h-[14px]' />
                                <small className="text-[#F74445] font-medium text-xs">
                                {touched.full_name && errors.full_name}
                                </small>
                            </div>
                            ) : null}
                        </div>

                        <div className='relative mt-8'>
                            <label
                            htmlFor="email"
                            className="block mb-3 text-[#303030] text-sm font-sans-medium"
                            >
                            Email Address
                            </label>
                            <div className='relative'>
                            <input
                            type="text"
                            name="email"
                            placeholder="e.g johndoe@email.com"
                            className={
                                touched.email && errors.email
                                ? "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm  text-[#121212] text-base focus:border-[#F74445] focus:outline-none rounded-[28px] border border-[#B92043] bg-[#FEECEC] py-4 px-6"
                                : "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6"
                            }
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            />                      
                            {touched.email && errors.email ? ( <img src={warning_icon} alt="warning" className='w-[14px] h-[14px] absolute top-1/2 transform text-[#3A3A3A] text-sm font-medium -translate-y-1/2 right-6 cursor-pointer' />): null}
                        </div>

                            {touched.email && errors.email ? (
                            <div className="flex gap-1 mt-2 items-center">
                                <img src={warning_icon} alt="warning" className='w-[14px] h-[14px]' />
                                <small className="text-[#F74445] font-medium text-xs">
                                {touched.email && errors.email}
                                </small>
                            </div>
                            ) : null}
                        </div>

                        <div className='relative mt-8'>
                            <label
                            htmlFor="phone_number"
                            className="block mb-3 text-[#303030] text-sm font-sans-medium"
                            >
                            Phone Number
                            </label>
                            <div className='flex gap-4'>  
                                <div className='border flex gap-2 items-center justify-center border-[#E4E4E4] bg-[#F8F8F8] rounded-[28px] basis-[20%]'>
                                    <img src={nigeria} alt="nigeria" className='w-[24px] h-[24px]' />
                                    <p className='text-[#121212] text-base font-sans'>+234</p>
                                </div>
                                <div className='relative basis-[80%]'>
                                    <input
                                    type="text"
                                    name="phone_number"
                                    placeholder="Phone number"
                                    className={
                                        touched.phone_number && errors.phone_number
                                        ? "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm  text-[#121212] text-base focus:border-[#F74445] focus:outline-none rounded-[28px] border border-[#B92043] bg-[#FEECEC] py-4 px-6"
                                        : "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6"
                                    }
                                    value={values.phone_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    />                      
                                    {touched.phone_number && errors.phone_number ? ( <img src={warning_icon} alt="warning" className='w-[14px] h-[14px] absolute top-1/2 transform text-[#3A3A3A] text-sm font-medium -translate-y-1/2 right-6 cursor-pointer' />): null}
                                </div>
                           
                           </div>

                            {touched.phone_number && errors.phone_number ? (
                            <div className="flex gap-1 mt-2 items-center">
                                <img src={warning_icon} alt="warning" className='w-[14px] h-[14px]' />
                                <small className="text-[#F74445] font-medium text-xs">
                                {touched.phone_number && errors.phone_number}
                                </small>
                            </div>
                            ) : null}
                        </div>

                        <div className="mt-8">
                            <label
                            htmlFor="password"
                            className="block mb-3 text-[#303030] text-sm font-sans-medium"
                            >
                            Password
                            </label>
                            <div className="relative">
                            <input
                                type={passwordShown ? "text" : "password"}
                                name="password"
                                placeholder="Password"
                                className={
                                    touched.password && errors.password
                                    ? "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm  text-[#121212] text-base focus:border-[#F74445] focus:outline-none rounded-[28px] border border-[#F74445] bg-[#FEECEC] py-4 px-6"
                                    : "appearance-none w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-base focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6"
                                }
                                value={values.password}
                                 onChange={(e) => {
                                    handleChange(e);
                                    validatePassword(e.currentTarget.value);
                                    }}
                                onBlur={handleBlur}
                            />
                            <div className='flex gap-1 items-center'> 
                                <p
                                    onClick={togglePasswordVisiblity}
                                    className={`absolute top-1/2 transform text-[#3A3A3A] text-sm font-sans-medium -translate-y-1/2 ${touched.password && errors.password ? 'right-12' : 'right-6'} cursor-pointer`}>
                                    {passwordShown ? 'Hide' : 'Show'}
                                </p>
                                {touched.password && errors.password ? ( <img src={warning_icon} alt="warning" className='w-[14px] h-[14px] absolute top-1/2 transform text-[#3A3A3A] text-sm font-medium -translate-y-1/2 right-6 cursor-pointer' />): null}
                            </div>
                            </div>
                            {touched.password && errors.password ? (
                            <div className="flex gap-1 items-center mt-2">
                                <img src={warning_icon} alt="warning" className="w-[14px] h-[14px]" />
                                <small className="text-[#F74445] font-medium text-xs">
                                {touched.password && errors.password}
                                </small>
                            </div>
                            ) : null}
                        </div>
                        <div className="mt-5">
                          <h6 className='text-[#6C6C6C] font-sans-medium text-sm'>Password Requirements</h6>
                             {Object.entries(passwordErrors).map(([key, value]) => {
                                return (
                                    <div className='flex gap-2 items-center mt-4'>
                                    <div className={`w-[10px] h-[10px] ${value ? 'bg-[#3A3A3A]' : 'bg-[#E5E5E5]'}  rounded-full`}></div>
                                    <div className='font-sans text-[#949494] text-xs'>
                                        {key === 'minValueValidation' && 'Must be a minimum of 6 characters'}
                                        {key === 'numberValidation' && 'Must have at least 1 number'}
                                        {key === 'capitalLetterValidation' && 'Must have at least 1 uppercase letter'}
                                        {key === 'specialCharacterValidation' && 'Must have at least 1 special character'}
                                    </div>
                                    </div>
                                )
                                })}
                                </div>

                        <div className="mt-10">
                            <button
                            type="submit"
                            disabled={!(isValid && dirty) || loading || !passwordErrors.capitalLetterValidation || !passwordErrors.minValueValidation || !passwordErrors.numberValidation || !passwordErrors.specialCharacterValidation}
                            className="bg-[#7C44BD] 
                                        disabled:bg-[#ABB6AC] 
                                        rounded-[233px] w-full py-5 text-[#FEFEFE] font-sans-medium text-sm hover:bg-[#7C44BD] hover:bg-opacity-[0.9] transition-all"
                            >
                            Create account
                            </button>
                         </div>

                         <div className='mt-4 flex gap-2 items-center justify-center'>
                             <input id="acceptTerms" name="acceptTerms" type="checkbox"
                               onChange={(e) =>
                                setFieldValue(
                                  "acceptTerms",
                                  e.target.checked.toString()
                                )
                              }
                              className="w-4 h-4 text-[#7C44BD] bg-transparent border-[#7C44BD] rounded focus:ring-[#7C44BD] focus:ring-0"
                              value={values.acceptTerms} />
                            <p 
                            className={
                                (errors.acceptTerms && touched.acceptTerms
                                  ? "text-[#F74445] text-sm font-sans"
                                  : "text-[#3A3A3A] text-sm font-sans")
                              }>By creating an account, you agree to our <Link to="/" className='font-sans-medium text-[#7C44BD]'>Terms of service</Link> and <Link to="/" className='font-sans-medium text-[#7C44BD]'>Privacy Policy</Link></p>
                         </div>

                        </Form>
                    )}
                    </Formik>
                </div>
                }

                {/* step 2 */}
               {step === 2 && 
               <div className='mt-10'>

                <div onClick={() => setStep(1)} className='flex gap-2 items-center cursor-pointer'>
                    <div className='flex justify-center items-center bg-[#EFEFEF] w-[40px] h-[40px] rounded-full'>
                        <img src={arrow_left} alt="arrow left" />
                    </div>
                    <div>
                        <p className='text-[#3A3A3A] text-sm font-sans'>Go back</p>
                    </div>
                </div>

                    <div className='mt-10'>
                        <h3 className='text-[#3A3A3A] font-familjen font-medium text-3xl'>Email verification</h3>
                        <p className='text-[#3A3A3A] text-sm font-sans mt-1'>A 6 digits code was sent to your email {useremail ? useremail : "user@email.com"}</p>
                    </div>

                    <div className='mt-8'>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputType='tel'
                        shouldAutoFocus={true}
                        renderSeparator={<span className='mx-3 text-[#BDBDBD]'>-</span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{ borderColor: "#E4E4E4", width: '60px', height: '60px', color: '#7C44BD', borderRadius: '10px', background: '#F8F8F8', borderWidth: '1px' }}
                        />
                    </div>

                    <div className="mt-10">
                            <button
                            type="submit"
                            onClick={handleVerifyMerchant}
                            disabled={otpDisabled || loading}
                            className="bg-[#7C44BD] 
                                        disabled:bg-[#E8E8E8] 
                                        rounded-[233px] w-full py-5 text-[#FEFEFE] font-sans-medium text-sm hover:bg-[#7C44BD] hover:bg-opacity-[0.9] transition-all"
                            >
                            Continue
                            </button>
                     </div>
                     {
                        minutes === 0 && seconds === 0 
                            ?
                            <div className='mt-2 flex justify-center'>
                                <p className='text-[#3A3A3A] text-sm font-sans mt-1'>Resend code</p>
                            </div>
                            :
                        <div className='mt-2 flex justify-center'>
                            <p className='text-[#3A3A3A] text-sm font-sans mt-1'>Didn’t receive code? <span className='text-[#949494]'>Resend in {formattedTime}</span></p>
                        </div>
                        }
                </div>
                }

                {/* step 3 */}
               {step === 3 &&
                <div className='mt-5'>
                    <div onClick={() => setStep(2)} className='flex gap-2 items-center cursor-pointer'>
                        <div className='flex justify-center items-center bg-[#EFEFEF] w-[40px] h-[40px] rounded-full'>
                            <img src={arrow_left} alt="arrow left" />
                        </div>
                        <div>
                            <p className='text-[#3A3A3A] text-sm font-sans'>Go back</p>
                        </div>
                    </div>

                    <div className='mt-8'>
                        <h3 className='text-[#3A3A3A] font-familjen font-medium text-3xl'>Company information</h3>
                    </div>

                    <div className='mt-8'>
                        <div>
                            <p className='text-[#303030] font-sans-medium text-sm'>Company Logo</p>
                        </div>
                        <div className='flex justify-between items-center mt-4'>
                            <div className='flex gap-4 items-center'>
                                {previewUrl
                                ?
                                <div className=''>
                                     <img src={previewUrl} alt="Preview" className='max-w-full w-[112px] object-cover h-[112px] rounded-full' />
                                 </div>
                                : 
                                <div className='w-[112px] h-[112px] relative flex justify-center items-center rounded-full overflow-hidden bg-[#E6F8FF]'>
                                    <div className='overflow-hidden absolute bottom-0'>
                                        <img src={building_tall} alt="building_tall" className='' />
                                    </div>
                                </div>
                                }
                                <div>
                                    <p className='text-[#949494] font-sans text-sm'>Minimum size: <span className='font-sans-medium'>800x800 pixels</span></p>
                                    <p className='text-[#949494] font-sans text-sm mt-1'>Formats: <span className='font-sans-medium'>JPG, PNG</span></p>
                                    <p className='text-[#949494] font-sans text-sm mt-1'>Max file size: <span className='font-sans-medium'>6MB</span></p>
                                </div>
                            </div>
                            <div>
                               {!imgLoader ? <label className='bg-[#F4F4F4] py-5 px-8 rounded-[233px] text-[#121212] font-sans-medium text-sm cursor-pointer'>
                                    <input 
                                    ref={fileRef}
                                    type="file"
                                    accept="image/png, image/jpeg"  
                                    onChange={() => changedHandler()}
                                    className='hidden'/>
                                   {previewUrl ? 
                                   <span>Change Logo</span>
                                   :
                                   <span>Upload new logo
                                   </span>
                                   }
                                 
                                </label>  
                                :
                                <div>
                                    <button className='bg-[#F4F4F4] py-4 px-6 flex justify-center gap-2 items-center rounded-[233px] text-[#121212] font-sans-medium text-sm cursor-pointer'>
                                        Uploading
                                        <ThreeDots
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#7C44BD"
                                        radius="9"
                                    />
                                    </button>
                                </div>
                                }
                            </div>
                        </div>
                 
                    </div>

                    <div className='relative mt-8'>
                            <label
                            htmlFor="business_name"
                            className="block mb-3 text-[#303030] text-sm font-sans-medium"
                            >
                            Business Name 
                            </label>
                            <div className='relative'>
                            <input
                            type="text"
                            name="business_name"
                            placeholder="e.g Figma"
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                            className="appearance-none font-sans w-full placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-[15px] focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6"
                            />                      
                        </div> 
                     </div>

                     <div className='relative mt-8'>
                            <label
                            htmlFor="business_industry"
                            className="block mb-3 text-[#303030] text-sm font-sans-medium"
                            >
                            Business industry 
                            </label>
                            <div className='relative'>
                                <select
                                 ref={selectRef}
                                value={businessIndustry}
                                onChange={(e) => setBusinessIndustry(e.target.value)}
                                className="appearance-none cursor-pointer w-full font-sans placeholder:text-[#949494] placeholder:text-sm text-[#121212] text-[15px] focus:border-[#E4E4E4] focus:outline-none rounded-[28px] border border-[#E4E4E4] bg-[#F8F8F8] py-4 px-6"
                                >
                                <option value="" disabled selected>Select industry</option>
                                <option value="retail">Retail</option>
                                <option value="hospitality">Hospitality</option>
                                <option value="e-commerce">E-commerce</option>
                                <option value="finance-crypto">Finance and Crypto</option>
                                <option value="health-fitness">Health and Fitness</option>
                                <option value="food-beverage">Food and Beverage</option>
                                <option value="telecommunications">Telecommunications</option>
                                <option value="automotive">Automotive</option>
                                <option value="technology">Technology</option>
                                <option value="education">Education</option>
                                <option value="gaming">Gaming</option>
                                <option value="travel-tourism">Travel and Tourism</option>
                                <option value="insurance">Insurance</option>
                                <option value="real-estate">Real Estate</option>
                                <option value="manufacturing">Manufacturing</option>
                                <option value="pharmaceuticals">Pharmaceuticals</option>
                                <option value="professional-services">Professional Services</option>
                                <option value="entertainment">Entertainment</option>
                                <option value="non-profit">Nonprofit</option>
                                <option value="utilities">Utilities</option>
                            </select>         
                            <img
                             src={caret_down} alt="caret down" 
                             className='absolute right-6 top-1/2 -translate-y-1/2  cursor-pointer'
                             onClick={handleSelectClick}
                              />
             
                        </div> 
                     </div>

                     <div className="mt-10">
                            <button
                            type="submit"
                            onClick={handleCompleteReg}
                            disabled={logoUrl === "" || businessName === "" || businessIndustry === "" || loading}
                            className="bg-[#7C44BD] 
                                        disabled:bg-[#ABB6AC] 
                                        rounded-[233px] w-full py-5 text-[#FEFEFE] font-sans-medium text-sm hover:bg-[#7C44BD] hover:bg-opacity-[0.9] transition-all"
                            >
                            Complete
                            </button>
                      </div>


                </div>
                }
            </div>

        </div>
        </>
     );
}
 
export default SignUpPage;