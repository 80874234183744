import * as Yup from "yup";

export const loginValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export const onboardMerchantValidator = Yup.object({
  full_name: Yup.string().required("Full name is required"),
  phone_number: Yup.string()
  .min(11, "Phone number cannot be less than 11 digits")
  .max(11, "Exceeded characters for phone number")
  .required("Phone number is required")
  .matches(/^-?[0-9]+(.[0-9]{1-7})?$/, "Enter a valid phone number"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  acceptTerms: Yup.bool().oneOf(
    [true],
    "Accept Terms & Conditions is required"
  ),
});

export const forgotValidator = Yup.object({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
});

export const resetPasswordValidator = Yup.object({
  password: Yup.string().required("Password is required"),
  confirm_password: Yup.string()
    .required("Passwords must match")
    .oneOf([Yup.ref("password"), ""], "Passwords must match"),
});