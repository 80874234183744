import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {completeOnboardProcess, onboardMerchant, uploadBusinessLogo, userForgotPassword, userLogin, userResetPassword, validateForgotOTP, verifyMerchantEmail} from "./authActions";


type AuthState = {
    isAuth: boolean;
    loading: boolean;
    token: string;
    profile: {
      email: string;
      full_name: string;
      merchant_id: string;
      phone_number: string;
      is_email_verified:  boolean,
      id: string;
      business_name: string;
      business_industry: string;
      business_logo: string;
    };
    encrypted_data: string;
    logoUrl: string;
  };


const initialState: AuthState = {
    isAuth: false,
    loading: false,
    token: "",
    profile: {
        email: "",
        full_name: "",
        merchant_id: "",
        id: "",
        phone_number: "",
        is_email_verified: false,
        business_name: "",
        business_industry: "",
        business_logo: "",
    },
    encrypted_data: "",
    logoUrl: ""
};


  export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
      logOut: () => {
        localStorage.setItem("token", "");
        localStorage.clear();
  
        return initialState;
      },
      logIn: (state, action: PayloadAction<string>) => {
        state.isAuth = true;
      },
      clearEncryptedData: (state) => {
        state.encrypted_data = "";
        state.logoUrl = "";
      }
    },
    extraReducers: (builder) => {   
      // login 
      builder.addCase(userLogin.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(
        userLogin.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.isAuth = true;
          state.token = action.payload.data.token
          state.profile = action.payload.data
          localStorage.setItem("token", action.payload.data.token);
        }
      );
      builder.addCase(userLogin.rejected, (state) => {
        state.loading = false;
      });
      // forgot password initiate
      builder.addCase(userForgotPassword.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(
        userForgotPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.encrypted_data = action.payload.data.temp_token
        }
      );
      builder.addCase(userForgotPassword.rejected, (state) => {
        state.loading = false;
      });
      // validate forgot password otp
      builder.addCase(validateForgotOTP.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(
        validateForgotOTP.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
        }
      );
      builder.addCase(validateForgotOTP.rejected, (state) => {
        state.loading = false;
      });
      // reset password
      builder.addCase(userResetPassword.pending, (state) => {
        state.loading = true;
      })
      builder.addCase(
        userResetPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
        }
      )
      builder.addCase(userResetPassword.rejected, (state) => {
        state.loading = false;
      })
      // Onboard Merchant
      builder.addCase(onboardMerchant.pending, (state) => {
        state.loading = true;
      })
      builder.addCase(
        onboardMerchant.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.encrypted_data = action.payload.data.temp_token
        }
      )
      builder.addCase(onboardMerchant.rejected, (state) => {
        state.loading = false;
      })
      // verify merchant email 
      builder.addCase(
        verifyMerchantEmail.pending,
        (state, action: PayloadAction<any>) => {
          state.loading = true;
         
        }
      )
      builder.addCase(
        verifyMerchantEmail.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.token = action.payload.data.token
          localStorage.setItem("token", action.payload.data.token);
          state.profile = action.payload.data
        }
      )
      builder.addCase(verifyMerchantEmail.rejected, (state) => {
        state.loading = false;
      })
      // Upload business logo
      builder.addCase(uploadBusinessLogo.pending, (state) =>{
        state.loading = true;
      })
      builder.addCase(uploadBusinessLogo.fulfilled,(state, action: PayloadAction<any>) => {
        state.loading = false;
        state.logoUrl = action.payload.data.business_logo
      })
      builder.addCase(uploadBusinessLogo.rejected, (state) =>{
        state.loading = false;
      })
      // Complete onboard process
      builder.addCase(completeOnboardProcess.pending, (state) =>{
        state.loading = true;
      })
      builder.addCase(completeOnboardProcess.fulfilled,(state, action: PayloadAction<any>) => {
        state.loading = false;
        state.isAuth = true;
        state.profile.business_logo = action.payload.data.business_logo
        state.profile.business_name = action.payload.data.business_name
        state.profile.business_industry = action.payload.data.business_industry
        state.profile.merchant_id = action.payload.data.merchant_id
      })
      builder.addCase(completeOnboardProcess.rejected, (state) =>{
        state.loading = false;
      })
    },
  });
  
export const { logOut, logIn,clearEncryptedData } = authSlice.actions;
  
export default authSlice.reducer;