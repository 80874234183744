import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from './pages/Auth/login';
import SignUpPage from './pages/Auth/signup';
import ForgotPasswordPage from './pages/Auth/forgotpassword';
import Sidebar from './components/Sidebar';
import DashboardPage from './pages/Dashboard';
import PointsPage from './pages/Program/Points';
import ReferralsPage from './pages/Program/Referrals';
import CustomersPage from './pages/Customer';

function App() {
  return (
    <div className="App">
       <Router>
        <Routes>
             <Route path="/" element={<LoginPage />} />
             <Route path="/create-account" element={<SignUpPage />} />
             <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
             <Route
              path="/dashboard"
              element={
                 <Sidebar title="Dashboard" children={<DashboardPage />} />       
              }
             />
            <Route
              path="/points"
              element={
                 <Sidebar title="Points" children={<PointsPage />} />       
              }
             />
              <Route
              path="/referrals"
              element={
                 <Sidebar title="Points" children={<ReferralsPage />} />       
              }
             />
               <Route
              path="/customers"
              element={
                 <Sidebar title="Customers" children={<CustomersPage />} />       
              }
             />
        </Routes>
        </Router>
    </div>
  );
}

export default App;
